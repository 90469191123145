<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <h5 class="card-title fw-bold mb-15">Thanks for your order</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwiperSlider",
};
</script>