<template>
  <div class="products-sidebar-filter bg-white letter-spacing mb-25">
    <div class="title">
      <h5 class="mb-0 fw-semibold text-secondary">Filters</h5>
    </div>
    <div class="sidebar-item">
      <h6 class="text-black fw-bold fs-md-15">Search</h6>
      <form class="search-box position-relative mb-15">
        <input
          type="text"
          class="form-control shadow-none text-black rounded-0 border-0"
          placeholder="Search product"
          v-model="search"
          @keyup="applyFilters"
        />
      </form>
    </div>
    <div class="sidebar-item">
      <h6 class="text-black fw-bold fs-md-15">Category</h6>
      <div v-if="loading">Loading categories...</div>
      <ul class="brands-list ps-0 mb-0 list-unstyled">
        <li v-if="categories.length === 0 && !loading" class="text-paragraph">
          No categories
        </li>
        <li v-else
          class="d-flex align-items-center justify-content-between text-paragraph"
          v-for="(category, index) in categories"
          :key="index"
        >
          <div class="form-check mb-0">
            <input
              class="form-check-input shadow-none"
              type="checkbox"
              :value="category.id"
              v-model="selectedCategory"
              @change="selectCategory()"
            />
            <label class="form-check-label fs-md-15 fw-medium" :for="category.id">
              {{ category.label }}
            </label>
          </div>
          <span class="d-block fw-medium text-muted"></span>
        </li>
       
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import mitt from '@/EventBus';


export default defineComponent({
  name: "SideBar",
  data(){
    return {
      categories: [],
      search:'',
      selectedCategory: [],
      loading: true
    }
  },
  mounted() {
    const emitter = mitt;
    emitter.on('fetchByCatalog', function(id){
      
      this.search = '';
      this.fetchCategories(id);
    }.bind(this));
    
    emitter.on('loadProducts', function(){            
      this.search = '';
      this.fetchCategories();
    }.bind(this));

    this.fetchCategories(this.$route.params.id);
  },
  methods: {
    selectCategory() {

      this.$emit("categorySearch", [...this.selectedCategory]);
      
    },
    applyFilters() {
      this.$emit('search', this.search);
    },
    applyCategoryFilter(categoryId) {
      this.$emit('categorySearch', categoryId);
    },
    async fetchCategories(id) {
      const config = {
        method: 'get',
        url: `/categories`,
        params: {
          sortfield: 't.label',
          sortorder: 'ASC',
          type: 'product',
          sqlfilters: id ? `(ef.api_brand:=:${id})` : '',
        }
      };
      axios(config)
        .then(response => {          
          this.loading = true;
          this.categories = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
      });
    },
  }
});
</script>
