<template>
  <header
    :class="[ 
      'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed',
      { sticky: isSticky },
    ]"
    id="header"
  >
    <div class="row align-items-center">
      <div class="col-xl-4 col-lg-5 col-md-6">
        <div
          class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center"
        >
          <button
            class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0"
            id="header-burger-menu"
            @click="stateStoreInstance.onChange"
          >
            <i class="flaticon-menu-1"></i>
          </button>
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 col-md-6">
        <div
          class="header-right-side d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <div class="dropdown notification-dropdown">
            <router-link to="/cart">
              <button
                class="dropdown-toggle p-0 position-relative bg-transparent border-0 transition lh-1"
                type="button"
                aria-expanded="false"
              >
                <i class="flaticon-shopping-cart"></i>
                <span
                  class="dot-badge position-absolute fw-bold rounded-circle text-white bg-primary d-inline-block"
                >
                  {{ storeProduct.totalCartItem }}
                </span>
              </button>
            </router-link>
          </div>
          <div class="dropdown profile-dropdown">
            <button
              class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">{{ user.user.name }}</span>
                <span class="text-body-emphasis fw-semibold fs-13">Customer</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import stateStore from "../../utils/store";
import { useStoreProduct } from "@/stores/storeProduct.js";

export default defineComponent({
  name: "MainHeader",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const stateStoreInstance = stateStore;
    const isSticky = ref(false);
    const storeProduct = useStoreProduct();
    
    onMounted(() => {      
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        isSticky.value = scrollPos >= 100;
      });
    });

    return {
      isSticky,
      stateStoreInstance,
      storeProduct
    };
  },
});
</script>
