import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useNotification } from '@kyvg/vue3-notification'; // Import de useNotification
import Swal from 'sweetalert2'; // Import de SweetAlert2

export const useStoreProduct = defineStore('product', () => {
    // Récupération de la fonction de notification
    const { notify } = useNotification();

    // state
    const cartItems = ref(JSON.parse(localStorage.getItem('cartItems')) || []);
    const MAX_CART_ITEMS = 200; // Limite maximale d'articles dans le panier

    // getters
    const totalCartItem = computed(() => {
        return cartItems.value.length;
    });

    // actions
    const addItem = (addCartItem) => {
      if (cartItems.value.length >= MAX_CART_ITEMS) {
          // Afficher l'alerte SweetAlert
          Swal.fire({
            title: `Limit reached: ${MAX_CART_ITEMS} products`,
            text: "You can only add up to 200 products per order. Please complete this order and create a new one for the remaining items.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, go to cart!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                // Redirect to cart
                window.location.href = '/cart'; // Replace with your cart URL
            }
        });
        
          return; // Empêche l'ajout
      }

      const newEntry = Object.assign({}, addCartItem);
      cartItems.value.push(newEntry);
  };

    const deleteFromCart = (deleteId) => {
        const index = cartItems.value.findIndex(item => item.id === deleteId);
        if (index > -1) {
            cartItems.value.splice(index, 1);
            notify({
              type: "info",
              text: `Product deleted to cart successfully.`,
          });
        }
    };

    const isAlreadyAdded = (id) => {
        return cartItems.value.some(item => item.id === id);
    };

    const increaseItemNumberById = (productId) => {
        const product = cartItems.value.find((item) => item.id === productId);
        if (Number(product.stock_reel) > Number(product.itemNumber) && 
            (Number(product.itemNumber) + Number(product.array_options?.options_qtymin)) < Number(product.stock_reel)) {
            product.itemNumber = Number(product.itemNumber) + Number(product.array_options?.options_qtymin);
        } else {
            product.itemNumber = Number(product.stock_reel);
        }
    };

    const decreaseItemNumberById = (productId) => {
        const product = cartItems.value.find((item) => item.id === productId);

        if (product) {
            const qtyMin = Number(product.array_options?.options_qtymin); // Quantité minimale
            let currentQty = Number(product.itemNumber); // Quantité actuelle

            // Si la quantité actuelle est supérieure à qtyMin, on peut réduire
            if (currentQty > qtyMin) {
                // Calcul du dernier multiple de `options_qtymin` inférieur ou égal à la quantité actuelle
                let newQuantity = currentQty - qtyMin;

                // Si la nouvelle quantité n'est pas un multiple de qtyMin, on revient au dernier multiple de qtyMin inférieur
                if (newQuantity % qtyMin !== 0) {
                    newQuantity = Math.floor(currentQty / qtyMin) * qtyMin; // Retourner au dernier multiple inférieur de qtyMin
                }

                // Ne pas descendre en dessous de la quantité minimale
                product.itemNumber = newQuantity >= qtyMin ? newQuantity : qtyMin;
            }
        }
    };

    const clearCart = () => {
        cartItems.value = [];
        localStorage.removeItem('cartItems');
    };

    // watch
    watch(cartItems, (newCartItems) => {
        newCartItems.forEach(product => {
            product.totalPrice = product.price * product.itemNumber;
        });

        localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    }, { deep: true });

    // computed
    const grossTotal = computed(() => {
        return cartItems.value.reduce((accumulator, { totalPrice }) => {
            return accumulator + totalPrice;
        }, 0);
    });

    // return
    return { 
        cartItems, 
        addItem, 
        totalCartItem, 
        deleteFromCart, 
        clearCart, 
        isAlreadyAdded, 
        increaseItemNumberById, 
        decreaseItemNumberById, 
        grossTotal 
    };
});
