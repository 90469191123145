<template>
  <div
    class="card mb-25 border-0 rounded-0 bg-white letter-spacing product-details-box"
  >
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-9">
          <swiper
            :slidesPerView="1"
            :loop="true"
            :autoplay="{
              delay: 4000,
              disableOnInteraction: true,
            }"
            :spaceBetween="10"
            :thumbs="{ swiper: thumbsSwiper }"
            :modules="[Thumbs]"
            class="productDetailsSwiper"
          >
            <swiper-slide v-for="product in products" :key="product.id">
              <img :src="product.image" alt="product-details" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="col-lg-2 col-md-3">
          <swiper
            :direction="'vertical'"
            :loop="true"
            :autoplay="{
              delay: 4000,
              disableOnInteraction: false,
            }"
            :spaceBetween="25"
            :slidesPerView="4"
            :freeMode="true"
            :watchSlidesProgress="true"
            :breakpoints="{
              0: {
                direction: 'horizontal',
                spaceBetween: 15,
              },
              768: {
                direction: 'vertical',
                spaceBetween: 25,
              },
            }"
            :modules="[Thumbs]"
            @swiper="setThumbsSwiper"
            class="productDetailsThumbSwiper"
          >
            <swiper-slide v-for="product in products" :key="product.id">
              <img :src="product.image" alt="product-details" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="col-lg-5 col-md-12">
          <div class="product-details-content">
            <h3 class="text-black fw-semibold">
              <span :style="{ color: product.ref && product.ref.endsWith('UK') ? 'red' : 'inherit' }">{{ product.ref }}</span> - {{ product.label }}
            </h3>
            <div class="mt-10 mb-12 mb-md-15 price d-flex align-items-center">
              <span class="text-primary fw-bold fs-md-15 fs-lg-16">
                {{ product.price && !isNaN(product.price) ? parseFloat(product.price).toFixed(2) : 'N/A' }} € | Ecotax: {{ product.array_options?.options_ecotax_unit_amount && !isNaN(product.array_options.options_ecotax_unit_amount) ? parseFloat(product.array_options.options_ecotax_unit_amount).toFixed(2) : '0' }} €
              </span>
            </div>
            <p 
  class="text-paragraph fs-md-15 fs-xxxl-16 lh-base"
  v-html="sanitizedDescription"
></p>

            <ul class="info ps-0 list-unstyled">
              <li class="text-paragraph fs-md-15 fs-xxxl-16">
                <span class="text-black fw-semibold d-inline-block">Reference:</span>
                {{ product.ref }}
              </li>
              <li class="text-paragraph fs-md-15 fs-xxxl-16">
                <span class="text-black fw-semibold d-inline-block">EAN13:</span>
                {{ product.barcode }}
              </li>
              <li class="text-paragraph fs-md-15 fs-xxxl-16">
                <span class="text-black fw-semibold d-inline-block">
                  Origin:
                </span>
                {{productCountryName}}
              </li>
              <li class="text-paragraph fs-md-15 fs-xxxl-16">
                <span class="text-black fw-semibold d-inline-block">
                  Category:
                </span>
                {{categoryName}}
              </li>
              <li class="text-paragraph fs-md-15 fs-xxxl-16">
                <span class="text-black fw-semibold d-inline-block">Hs code:</span>
                {{ product.customcode }}
              </li>
            </ul>
            <span class="d-block text-paragraph">
              <span class="text-success">
                Stock: {{ product.stock_reel }} |
              </span>
              <span class="text-black">
                Minimum Qty: {{  product.array_options?.options_qtymin }}
              </span>
            </span>
            <div class="buttons-list d-sm-flex align-items-center">
              <div class="input-counter d-flex align-items-center">
                  <!-- Remplacez les appels à `incrementNumber` et `decrementNumber` par les fonctions suivantes -->
                  <div class="number-counter me-15" id="number-counter">
                    <button type="button" @click="decreaseItemNumber(product.id)">
                      <i class="flaticon-minus"></i>
                    </button>

                    <input type="number" :value="Math.min(product.itemNumber, product.stock_reel)" readonly />

                    <button type="button" @click="increaseItemNumber(product.id)">
                      <i class="flaticon-plus"></i>
                    </button>
                  </div>

                <button
                  class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-20 pe-20 pt-md-11 pb-md-11 ps-md-25 pe-md-25 ps-xl-40 pe-xl-40 rounded-1"
                  type="submit"
                  @click="addToCart(product.id)"
                >
                {{storeProduct.isAlreadyAdded(product.id) ? 'Added' : 'Add to cart'}}
                </button>
              </div>
              <button
                type="button"
                class="fav-btn mt-10 mt-sm-0 ms-sm-15 d-inline-block position-relative bg-transparent border-0 transition"
              >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUpdated } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Thumbs } from "swiper/modules";
import DOMPurify from "dompurify";
import axios from "axios";
import { useNotification } from "@kyvg/vue3-notification";
import { useStoreProduct } from "@/stores/storeProduct.js";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

export default {
  name: "ProductDetails",
  props: ["product"],
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props, { emit }) {
    const products = ref([
      {
        id: 1,
        image: "https://placehold.it/800",
        itemNumber: 0,
      },
      {
        id: 2,
        image: "https://placehold.it/800",
      },
      {
        id: 3,
        image: "https://placehold.it/800",
      },
      {
        id: 4,
        image: "https://placehold.it/800",
      },
    ]);

    const productCountryName = ref("");
    const categoryName = ref("");
    const thumbsSwiper = ref(null);
    const storeProduct = useStoreProduct();
    const { notify } = useNotification();
    

    const sanitizedDescription = computed(() => {
      if (props.product.description) {
        return DOMPurify.sanitize(props.product.description);
      }
      return "";
    });

    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    

    // Augmenter la quantité
  const increaseItemNumber = () => {
  const product = props.product; // Accéder au produit via les props
  if (!product) {
    console.error("Product is undefined");
    return; // Sortir de la fonction si le produit n'existe pas
  }

  const qtyMin = Number(product.array_options?.options_qtymin); // Quantité minimale à ajouter
  const stockReel = Number(product.stock_reel); // Stock réel disponible

  if (Number(product.itemNumber) < stockReel) {
    let newQuantity = Number(product.itemNumber) + qtyMin;

    if (newQuantity > stockReel) {
      product.itemNumber = stockReel;
    } else {
      product.itemNumber = newQuantity;
    }
  } else {
    product.itemNumber = stockReel;
  }
};


    // Diminuer la quantité
const decreaseItemNumber = () => {
  const product = props.product; // Accéder au produit via les props
  if (!product) {
    console.error("Product is undefined");
    return; // Sortir de la fonction si le produit n'existe pas
  }

  const qtyMin = Number(product.array_options?.options_qtymin); // Quantité minimale à ajouter
  let currentQty = Number(product.itemNumber);

  if (currentQty > qtyMin) {
    let newQuantity = currentQty - qtyMin;

    if (newQuantity % qtyMin !== 0) {
      newQuantity = Math.floor(currentQty / qtyMin) * qtyMin;
    }

    product.itemNumber = newQuantity >= qtyMin ? newQuantity : qtyMin;
  }
};


    const getCountryName = async () => {
      if (!props.product.country_id) {
        setTimeout(getCountryName, 500);
        return;
      }
      try {
        const response = await axios.get(
          `/setup/dictionary/countries/${props.product.country_id}`
        );
        const responseCategory = await axios.get(
          `/products/${props.product.id}/categories`
        );
        productCountryName.value = response.data.label;
        const categoryNames = responseCategory.data.map((cat) => cat.label);
        categoryName.value = categoryNames.join(", ");
      } catch (error) {
        console.error(error);
      }
    };

    const addToCart = async () => {
      if (props.product.stock_reel <= 0) {
        notify({
          type: "warn",
          text: "Product is out of stock!",
        });
        return;
      }
      if (!props.product.price) {
        notify({
          type: "warn",
          text: "Product has no price!",
        });
        return;
      }
      if (storeProduct.isAlreadyAdded(props.product.id)) {
        notify({
          type: "warn",
          text: "Product already added to cart!",
        });
        return;
      }

      storeProduct.addItem({
        ...props.product,
        totalPrice: props.product.itemNumber * props.product.price,
      });
      notify({
        type: "success",
        text: "Product added to cart successfully!",
      });
    };

    const fetchDocumentsForSingleProduct = async () => {
      if (!props.product.id) {
        setTimeout(fetchDocumentsForSingleProduct, 500);
        return;
      }
      try {
        const { data } = await axios.get("/documents", {
          params: {
            modulepart: "produit",
            id: props.product.id,
          },
        });

        if (!data || data.length === 0) {
          return;
        }

        const documentsData = data.map((document) => ({
          docid: document.id,
          filepath: document.filepath,
          filename: document.filename,
          image: `https://doli.gigan-products.com/documents/${document.filepath}/${document.filename}`,
        }));

        if (!documentsData) {
          products.value = [];
        }

        products.value = documentsData;
      } catch (error) {
        console.error(`Error fetching documents for product ID `, error);
      }
    };

    history.replaceState({}, "", "/product-details");

    onMounted(() => {
      getCountryName();
      fetchDocumentsForSingleProduct();
    });

    onUpdated(() => {
      fetchDocumentsForSingleProduct();
    });

    return {
      sanitizedDescription,
      storeProduct,
      products,
      productCountryName,
      categoryName,
      thumbsSwiper,
      setThumbsSwiper,
      Autoplay,
      Thumbs,
      increaseItemNumber,
      decreaseItemNumber,
      addToCart,
    };
  },
};


</script>

