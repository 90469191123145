<template>
  <div class="row">
    <div class="col-lg-8">
      <div
        class="card mb-25 border-0 rounded-0 bg-white shopping-cart-box letter-spacing"
      >
        <div class="card-body">
          <div class="table-responsive">
            <table class="table text-nowrap align-middle mb-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >#</th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >
                    Stock Available
                  </th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >
                    Total Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in storeProduct.cartItems" :key="product.id">
                  <td class="shadow-none">
                    <button
                      type="button"
                      class="bg-transparent p-0 border-0 text-paragraph fs-15 fs-md-16 fs-lg-18"
                      @click="storeProduct.deleteFromCart(product.id)"
                    >
                      <i class="ph-duotone ph-trash"></i>
                    </button>
                  </td>
                  <td class="shadow-none fw-medium text-black product-title">
                    <div class="d-flex align-items-center text-decoration-none text-black fs-md-15 fs-lg-16">
                     Ref: <span :style="{ color: product.ref && product.ref.endsWith('UK') ? 'red' : 'inherit' }">{{product.ref}}</span>
                    </div>
                    <div class="d-flex align-items-center text-decoration-none text-black fs-md-15 fs-lg-16">
                      {{product.label}}
                    </div>
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-paragraph">
                    {{ useCurrencify(product.price) && !isNaN(product.price) ? useCurrencify(product.price) : 'N/A' }}
                  </td>
                  <td class="shadow-none lh-1">
                    <div class="number-counter" id="number-counter">
                      <button type="button" @click="decreaseItemNumber(product.id)">
                        <i class="flaticon-minus"></i>
                      </button>
                      <input
                        type="number"
                        id="quantity-1"
                        :value="product.itemNumber"
                        min="1"
                        max="10"
                        readonly
                      />
                      <button type="button" @click="increaseItemNumber(product.id)">
                        <i class="flaticon-plus"></i>
                      </button>
                    </div>
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-paragraph">
                    {{product.stock_reel}}
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-paragraph">
                    {{ useCurrencify(product.totalPrice) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <form @submit.prevent="checkout">
            <div class="form-group mb-15">
              <label class="d-block text-black fw-semibold mb-10">
                Add A Note
              </label>
              <textarea
                v-model="note_private"
                cols="30"
                rows="9"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="Write some note here..."
              ></textarea>
            </div>
            <div class="text-end">
              <button
                class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                Checkout Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-summary-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-document text-info"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">Order Summary</h5>
        </div>
        <div class="card-body">
          <ul class="order-summary-list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-block text-muted text-uppercase fw-medium fs-13">
                DESCRIPTION
              </span>
              <span class="d-block text-muted text-uppercase fw-medium fs-13">
                PRICE
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-block text-paragraph fw-medium"> Total </span>
              <span class="d-block text-primary fs-md-15 fs-lg-16 fw-bold">
                {{ useCurrencify(storeProduct.grossTotal) }}
              </span>
            </li>
          </ul>
          <div class="coupon-code">
            <div class="box">
              <p class="text-success mb-0">
                Shipping costs will be calculated during order preparation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStoreProduct } from "@/stores/storeProduct.js";
import axios from "axios";
import { useNotification } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import { useCurrencify } from "@/composables/useCurrencify";
import { useStoreUser } from "@/stores/storeUser";
import Swal from "sweetalert2"; // Importation de SweetAlert2

const storeUser = useStoreUser();
const storeProduct = useStoreProduct();
const { notify } = useNotification();
const router = useRouter();
let note_private = "";

const checkout = async () => {
  try {
    // Affichage de la fenêtre de confirmation avant d'envoyer la requête
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure you want to checkout?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, checkout!",
      cancelButtonText: "Cancel",
    });

    // Si l'utilisateur confirme, on procède au checkout
    if (isConfirmed) {
      // D'abord, envoyez la requête de création de commande en POST
      let response = await axios.post(`/cartable`, {
        products: storeProduct.cartItems.map(({ id, itemNumber, tva_tx, price }) => ({
          id,
          itemNumber,
          tva_tx,
          price,
        })),
        socid: storeUser.user.soc_id,
        tva: storeUser.user.tva,
        note_private: note_private,
      }, {
        baseURL: process.env.VUE_APP_LARAVEL_BASE_URL,
        timeout: 30000,
      });

      // Ensuite, envoyez la requête pour valider la commande
      let validateResponse = await axios.post(`/validated`, {
        orderId: response.data.data,
      }, {
        baseURL: process.env.VUE_APP_LARAVEL_BASE_URL,
      });

      // Si le checkout est effectué avec succès
      storeProduct.clearCart();
      notify({
        type: "success",
        text: "Checkout successful!",
      });
      router.push({ name: "SwiperSliderPage" });

    } else {
      // Si l'utilisateur annule, affichez une notification
      notify({
        type: "error",
        text: "Checkout canceled.",
      });
    }
  } catch (error) {
    console.error(error);
    notify({
      type: "error",
      text: "An error occurred during the checkout process.",
    });
  }
};




const increaseItemNumber = (productId) => {
  storeProduct.increaseItemNumberById(productId);
};

const decreaseItemNumber = (productId) => {
  storeProduct.decreaseItemNumberById(productId);
};
</script>
