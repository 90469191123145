import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'
import axios from 'axios'
import router from '@/router';

export const useStoreUser = defineStore('User', () => {
    //state
    const token = ref('')
    const authenticated = ref(false)
    const user = ref(JSON.parse(localStorage.getItem('user')) || {})

    //getters
    const getToken = computed(() => {
        return localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : token.value
      })
    const getUser = computed(() => {
        return user.value
      })
    const getAuthenticated = computed(() => {
        return authenticated.value
      })
      
    //actions
    const setToken = (newToken) => {
        return new Promise((resolve, reject) => {
            token.value = newToken
            try {
                localStorage.setItem('token', JSON.stringify(newToken));
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    }

    const attempt = async () => {
        const token = localStorage.getItem('token')
        if(token){
            setToken(JSON.parse(token))
        }
        try {
            const response = await axios.get('/me', {
                baseURL: `https://b2b-adm.gigan-products.com/api`,
                headers: {
                    'Authorization': `Bearer ${getToken.value}`
                }
            })
            authenticated.value = true
            user.value = response.data
            localStorage.setItem('user', JSON.stringify(response.data));
        } catch (e) {            
            authenticated.value = false
            user.value = {}
        }
    }

    //watch

    //computed    
    const isLoggedIn = async() => {
        await attempt()
        return getAuthenticated.value
      }

    const auth = isLoggedIn()
        .then((res) => {
            if(!res){
                router.push({name: 'LoginPage'});
            }
        })
        .catch((err) => {
            console.log(err);
        });

    //return
    return {token, setToken, getToken, authenticated, user, getUser, getAuthenticated, attempt, isLoggedIn, auth}
  })