<template>
  <div class="row" v-if="!loading">
    <div class="col-lg-12 col-xl-12 col-xxl-4">
      <div class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing">
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-sterile-box text-primary"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Order Details - {{ orderDetails.ref }}
          </h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-calendar"></i>
                Data Added: {{ useDateFormat(orderDetails.date) }}
              </div>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-atm"></i>
                Payment Method:
              </div>
              <span
                class="d-flex align-items-center text-paragraph fs-md-15 fs-lg-16"
              >
                Bank
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-12 col-xxl-4">
      <div class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing">
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-user-3 text-success"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Customer Details
          </h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-user-1"></i>
                Customer Name:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ thirdparties.name }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-envelope"></i>
                Email:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ thirdparties.email }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-phone-call"></i>
                VAT Number:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ thirdparties.tva_intra }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-12 col-xxl-4">
      <div class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing">
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-document text-info"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">Documents</h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-copy"></i>
                Invoice: 
              </div>
              <span class="d-block text-primary fs-md-15 fs-lg-16">
                {{ orderInvoiceRef }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing">
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-sterile-box text-primary"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Order - {{ orderDetails.ref }}
          </h5>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table text-nowrap align-middle mb-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 ps-0"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pe-0"
                  >
                    Total Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="line in orderDetails.lines" :key="line.id">
                  <td class="shadow-none fw-medium text-black product-title">
                    <div class="d-flex align-items-center text-decoration-none text-black fs-md-15 fs-lg-16">
                      Ref: {{ line.product_ref }}
                    </div>
                    <div class="d-flex align-items-center text-decoration-none text-black fs-md-15 fs-lg-16">
                      {{ line.product_label }}
                    </div>
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-paragraph">
                    {{ useCurrencify(line.price) }}
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-paragraph">
                    {{ line.qty }}
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-paragraph pe-0">
                    {{ useCurrencify(line.price * line.qty) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing">
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-document text-info"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">Order Summary</h5>
        </div>
        <div class="card-body">
          <ul class="order-summary-list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-block text-muted text-uppercase fw-medium fs-13">
                DESCRIPTION
              </span>
              <span class="d-block text-muted text-uppercase fw-medium fs-13">
                PRICE
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-block text-paragraph fw-medium"> Total </span>
              <span class="d-block text-primary fs-md-15 fs-lg-16 fw-bold">
                {{ useCurrencify(lineTotal) }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { useCurrencify } from '@/composables/useCurrencify';
import { useDateFormat } from '@/composables/useDateFormat';
import { useStoreUser } from '@/stores/storeUser';

const storeUser = useStoreUser();
const route = useRoute();
const orderId = route.params.id;

const loading = ref(false);
const orderDetails = ref({});
const thirdparties = ref({});
const lineTotal = ref(0);
const orderInvoiceRef = ref('N/A');

const fetchOrderDetails = async () => {
  loading.value = true;
  history.replaceState({}, '', '/order-details'); // Cache l'ID dans l'URL
  
  try {
    const response = await axios.get(`/orders/${orderId}`);
    orderDetails.value = response.data;

    // Calcul du total des lignes de commande
    lineTotal.value = orderDetails.value.lines.reduce(
      (acc, line) => acc + line.price * line.qty,
      0
    );

    // Récupérer la référence de la facture
    await updateInvoiceRef();
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const fetchInvoiceRef = async (invoiceId) => {
  try {
    const response = await axios.get(`/invoices/${invoiceId}`);
    return response.data.ref || 'N/A';
  } catch (error) {
    console.error(`Erreur lors de la récupération de la facture:`, error);
    return 'N/A';
  }
};

const updateInvoiceRef = async () => {
  const facture = orderDetails.value.linkedObjectsIds?.facture;
  if (facture) {
    const invoiceId = Object.values(facture)[0];
    orderInvoiceRef.value = await fetchInvoiceRef(invoiceId);
  } else {
    orderInvoiceRef.value = 'N/A';
  }
};

const fetchThirdparty = async () => {
  const options = {
    method: 'GET',
    url: `thirdparties/${storeUser.user.soc_id}`,
  };

  try {
    const response = await axios.request(options);
    thirdparties.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

onMounted(async () => {
  await fetchOrderDetails();
  await fetchThirdparty();
});
</script>